import { combineReducers } from 'redux';

import requests from '../pages/Requests/reducers';
import similarUsers from '../pages/ViewDetails/reducers';
import applicationLog from '../pages/ViewDetails/reducers/applicationLog';
import paypalPayments from '../pages/ViewDetails/reducers/paypalPayments';

const reducer = combineReducers({ requests, similarUsers, applicationLog, paypalPayments });

export default reducer;
