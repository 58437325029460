import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';

import TPLoader from 'components/TP-UI/TPLoader';

import styles from './styles';

const TPTabPanel = ({
  content: Content,
  contentProps = {},
  panelProps = {},
  loading = false,
  selected = false,
  keepOnlySelected = true,
  children,
  ...props
}) => {
  const keepNodeStyles = keepOnlySelected
    ? undefined
    : {
        display: selected ? 'block' : 'none',
      };

  return Content || children ? (
    <Box role="tabpanel" sx={panelProps?.classes?.root} hidden={!selected} {...props}>
      {(!keepOnlySelected || selected) && (
        <Box sx={[styles.tabPanelContent, panelProps?.classes?.content, keepNodeStyles]}>
          {loading ? <TPLoader /> : Content ? <Content {...contentProps} /> : children}
        </Box>
      )}
    </Box>
  ) : null;
};

TPTabPanel.displayName = 'MuiTabPanel';
TPTabPanel.propTypes = {
  content: PropTypes.elementType,
  contentProps: PropTypes.object,
  panelProps: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  // The flag is used to render the contents of the tabs.
  // If true, then only the content of the selected tab is rendered. If false, then the contents of all tabs are rendered. By default, true
  keepOnlySelected: PropTypes.bool,
};

export default TPTabPanel;
