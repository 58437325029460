import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getPaypalPaymentsManagerSuccess](state, action) {
      return action.response.data.map((item, _id) => ({ ...item, _id }));
    },

    [actionCreators.getPaypalPaymentsManagerFail]() {
      return defaultState;
    },
  },
  defaultState,
);
