import React, { Suspense, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'modules/common/MainLayout';
import * as accountsSelectors from 'modules/accounts/pages/MyAccounts/selectors';
import * as accountsActionCreators from 'modules/accounts/pages/MyAccounts/actions';
import * as authSelectors from 'modules/auth/selectors';
import * as notificationsActions from 'modules/notifications/actions';
import { Routes } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import PaypalSimulate from '../components/PaypalSimulate';

const DepositFinmoContainer = () => {
  const history = useHistory();
  const user = useSelector(authSelectors.getUser);
  const accounts = useSelector(accountsSelectors.getLiveAccountsAsOptions);
  const dispatch = useDispatch();

  const onDepositSuccess = useCallback(() => {
    dispatch(notificationsActions.showNotificationInfo('Payment success'));
    history.push(Routes.PAYMENTS);
  }, [dispatch, history]);

  const onDepositError = useCallback(
    (err) => {
      dispatch(notificationsActions.showNotificationError('Payment error ' + err.message || ''));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(accountsActionCreators.getAccountsRequest({ isLive: true }));
  }, [dispatch]);

  return (
    <MainLayout title={'Simulate paypal'}>
      <Suspense>
        <PaypalSimulate
          accounts={accounts}
          user={user}
          onDepositSuccess={onDepositSuccess}
          onDepositError={onDepositError}
        />
      </Suspense>
    </MainLayout>
  );
};
export default withRouter(DepositFinmoContainer);
