export default (theme) => ({
  text: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.simpleGrey,
    lineHeight: '10px',
  },
  amount: {
    fontWeight: 600,
    color: theme.palette.textColor,
  },
});
