import { ACCOUNT_STATUSES } from 'constants/index';

import { sortBy, get } from 'lodash';

export const getLiveAccounts = (accounts = []) =>
  accounts.filter((account) => account.isLive && !account.isRebate);

export const getRebateAccounts = (accounts = []) =>
  accounts.filter((account) => account.isLive && account.isRebate);

export const getDemoAccounts = (accounts = []) => accounts.filter((account) => !account.isLive);

const _isNotHiddenAndArchived = (isHidden, isArchived) =>
  !isHidden && !get(isArchived, 'status', false);

export const sortFilterDepositableAccounts = (accounts = []) =>
  sortBy(accounts, ['createdAt']).filter(
    ({ isHidden, isArchived, status }) =>
      _isNotHiddenAndArchived(isHidden, isArchived) && status !== ACCOUNT_STATUSES.REJECTED,
  );

export const sortFilterTransferableAccounts = (accounts = []) =>
  sortBy(accounts, ['createdAt']).filter(
    ({ isHidden, isArchived, status }) =>
      _isNotHiddenAndArchived(isHidden, isArchived) && status === ACCOUNT_STATUSES.APPROVED,
  );

export const sortFilterWithdrawableAccounts = (accounts = []) =>
  sortBy(accounts, ['createdAt']).filter(
    ({ isHidden, isArchived, status }) =>
      _isNotHiddenAndArchived(isHidden, isArchived) && status === ACCOUNT_STATUSES.APPROVED,
  );

export const sortFilterDefaultAccounts = (accounts = []) =>
  sortBy(accounts, ['createdAt']).filter(({ isHidden, isArchived }) =>
    _isNotHiddenAndArchived(isHidden, isArchived),
  );
