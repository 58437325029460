import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from 'material-latest';

import { PAYMENT_METHODS } from 'constants/index';
import { required } from 'utils/validation/fieldValidationRules';
import { PAYMENT_METHODS_BASE_CURRENCIES } from 'modules/payments/pages/Deposit/constants';
import Picker from 'modules/common/Picker';
import TextInput from 'modules/common/TextInput';
import styles from './styles';
import { amount } from 'utils/normalizations';
import loader_small_secondary from 'static/loader_small_secondary.gif';
import getConvertedAmount from '../../helpers/getConvertedAmount';
import AccountBalance from '../AccountBalance';
import AccountMinAmount from '../AccountMinAmount';

const Account = ({
  classes,
  minAmount,
  minAmountString,
  accounts,
  selectedAccount,
  validateAmount = [required],
  hideCurrentBalance,
  column,
  accountChange,
  method,
  isConversionMessageVisible,
  rate,
  amountToConvert,
  toCurrency,
  isRatePending,
  isWithdrawal,
  isPending,
}) => {
  const renderBaseCurrencyField =
    method && (method === PAYMENT_METHODS.local || method === PAYMENT_METHODS.paymentAsia);
  const renderAmountField = method !== PAYMENT_METHODS.praxis;

  const { t } = useTranslation('payments');

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item xs={12} md={4} position="relative">
        <Field
          name="accountId"
          component={Picker}
          label={t('common:labels.account')}
          values={accounts}
          validate={[required]}
          menuPaperClass={classes.menuPaperClass}
          menuItemClass={classes.menuItemClass}
          //labelWidth={100}
          placeholder={t('common:placeholders.chooseAccount')}
          onChange={accountChange}
          props={{
            disabled: isPending,
          }}
        />
        {selectedAccount && (
          <Stack>
            {!hideCurrentBalance && (
              <AccountBalance
                amount={selectedAccount[isWithdrawal ? 'marginFree' : 'balance']}
                currency={selectedAccount.currency}
              />
            )}
            <AccountMinAmount
              minAmount={minAmount}
              minAmountString={minAmountString}
              currency={selectedAccount.currency}
            />
          </Stack>
        )}
      </Grid>
      {renderAmountField && (
        <Grid item xs={12} md={4}>
          <Field
            name="amount"
            component={TextInput}
            label={t('common:labels.amountOfMoney')}
            validate={selectedAccount ? validateAmount : [required]}
            normalize={amount}
            placeholder={t('common:labels.amount')}
            endAdornment={
              selectedAccount && (
                <InputAdornment disableTypography className={classes.amountCurrency}>
                  {selectedAccount.currency.toUpperCase()}
                </InputAdornment>
              )
            }
          />
          {isConversionMessageVisible && (
            <div className={classes.conversionRate}>
              <span className={classes.messageText}>{t('common:labels.finalConvertedAmount')}</span>
              <span className={classes.messageValue}>
                <span>
                  {toCurrency &&
                    amountToConvert &&
                    getConvertedAmount(amountToConvert, rate, toCurrency)}
                </span>
                {isRatePending && (
                  <img className={classes.rateLoading} alt="" src={loader_small_secondary} />
                )}
              </span>
            </div>
          )}
        </Grid>
      )}
      {renderBaseCurrencyField && (
        <Grid item xs={12} md>
          <Field
            name="currency"
            component={Picker}
            label={t('common:labels.baseCurrency')}
            values={PAYMENT_METHODS_BASE_CURRENCIES[method]}
            labelWidth={80}
            validate={[required]}
            placeholder={t('common:labels.baseCurrency')}
            disabled={method === PAYMENT_METHODS.paymentAsia}
          />
        </Grid>
      )}
      {!!column && (
        <Grid item xs={12} md>
          {column}
        </Grid>
      )}
    </Grid>
  );
};

Account.propTypes = {
  accounts: PropTypes.array.isRequired,
  selectedAccount: PropTypes.shape({
    currency: PropTypes.string.isRequired,
  }),
  accountChange: PropTypes.func.isRequired,
  minAmount: PropTypes.string,
  minAmountString: PropTypes.bool,
  hideCurrentBalance: PropTypes.bool,
  validateAmount: PropTypes.array,
  column: PropTypes.node,
  method: PropTypes.string,
  isWithdrawal: PropTypes.bool,
};

export default withStyles(styles)(Account);
