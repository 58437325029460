import axios from 'axios';

import React, { useCallback, useMemo } from 'react';
import config from '../../../../../../config';
import { compose } from 'redux';
import { reduxForm, formValues, Field } from 'redux-form';
import { Grid, TextField } from 'material-latest';
import { domain } from 'modules/api';
import Account from 'modules/payments/components/Account';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { PAYPAL_SDK_URL } from 'constants/appURL';

const { API_HOST } = config;
let configApi = {
  withCredentials: true,
  baseURL: domain,
  timeout: 300000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
    'Access-Control-Allow-Origin': config.API_HOST,
    'Access-Control-Allow-Credentials': true,
  },
  params: {},
};

// NEED TO REVISIT THIS FILE, since i used @paypal/react-paypal-js package instead of sdk script;
const PaypalSimulate = ({
  accountId,
  amount,
  user,
  accounts,
  onDepositSuccess,
  onDepositError,
}) => {
  const api = useMemo(() => axios.create(configApi), []);

  const createOrder = useCallback(() => {
    return api
      .post(`${API_HOST}/api/v1/payments/paypal-simulate-deposit`, {
        amount: Number(amount),
        account: accountId,
      })
      .then(function(res) {
        return res.data.id;
      });
  }, [amount, accountId, api]);

  const onApprove = useCallback(
    (data) => {
      return api
        .post(`${API_HOST}/api/v1/payments/paypal-simulate-deposit-approve`, {
          orderId: data.orderID,
          amount: Number(amount),
          accountId: accountId,
        })
        .then(onDepositSuccess)
        .catch(onDepositError);
    },
    [amount, accountId, api, onDepositSuccess, onDepositError],
  );

  const account = accounts.find((account) => account.value === accountId);
  const currency = account?.currency;

  return (
    <Grid container spacing={2}>
      <Grid item container lg={12}>
        <Account accounts={accounts} />
      </Grid>
      <Grid item container xs={12}>
        <Field
          component={TextField}
          id="amount"
          name="amount"
          type="number"
          placeholder="Amount"
          required
        />
      </Grid>
      <Grid item container xs={12}>
        <PayPalScriptProvider
          options={{
            // sdkBaseUrl: '',
            currency,
            clientId: 'sb',
            commit: false,
          }}>
          {!accountId || !amount || !user ? null : (
            <PayPalButtons
              disabled={!accountId || !amount || !user}
              onApprove={onApprove}
              createOrder={createOrder}
              style={{
                layout: 'horizontal', // horizontal | vertical
                size: 'responsive' /* medium | large | responsive*/,
                shape: 'pill' /* pill | rect*/,
                color: 'gold' /* gold | blue | silver | black*/,
                fundingicons: false /* true | false */,
                tagline: false /* true | false */,
              }}
            />
          )}
        </PayPalScriptProvider>
      </Grid>
    </Grid>
  );
};

export default compose(
  reduxForm({
    form: 'DEPOSIT_CARD_FORM_PAYPAL_SIMULATE',
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
  formValues('accountId'),
  formValues('amount'),
)(PaypalSimulate);
