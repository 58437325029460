import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, withStyles } from '@material-ui/core';

import Tooltip from 'modules/common/Tooltip/components/Tooltip';
import formatPrice from 'utils/formatPrice';
import styles from './styles';

function AccountMinAmount({ minAmount, minAmountString, classes, currency }) {
  const { t } = useTranslation('payments');

  if (!minAmount) return null;
  if (!minAmountString) {
    return (
      <div className={classes.tooltip}>
        <Tooltip
          label={t('withdraw.bankwire.tooltip.title') + minAmount}
          value={t('withdraw.bankwire.tooltip.description')}
        />
      </div>
    );
  }
  return (
    <Typography className={classes.text}>
      {t('withdraw.bankwire.tooltip.title')}
      <Typography className={classes.amount} inline>
        {formatPrice(minAmount, currency)}
      </Typography>
    </Typography>
  );
}

AccountMinAmount.propTypes = {
  minAmount: PropTypes.string,
  currency: PropTypes.string,
  minAmountString: PropTypes.bool,
};

export default withStyles(styles)(AccountMinAmount);
