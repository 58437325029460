import { handleActions } from 'redux-actions';
import { postJoinWaitlistFail, postJoinWaitlistSuccess, postJoinWaitlistCheck } from '../actions';

let userId;
const defaultState = {
  joinedWaitlist: false,
};

const setJoinedWaitlistInLocalStorage = (value) => {
  localStorage.setItem(`joinedDay1Waitlist.${userId}`, value);
};

export default handleActions(
  {
    [postJoinWaitlistCheck](state, { payload: id }) {
      userId = id;
      const userJoined = localStorage.getItem(`joinedDay1Waitlist.${userId}`) === 'true';

      return {
        ...state,
        joinedWaitlist: userJoined,
      };
    },
    [postJoinWaitlistFail](state) {
      setJoinedWaitlistInLocalStorage(false);

      return {
        ...state,
        joinedWaitlist: false,
      };
    },
    [postJoinWaitlistSuccess](state) {
      setJoinedWaitlistInLocalStorage(true);

      return {
        ...state,
        joinedWaitlist: true,
      };
    },
  },
  defaultState,
);
