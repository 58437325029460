import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';

const TPDivider = ({
  className,
  absolute = false,
  flexItem = false,
  orientation = 'horizontal',
  variant = 'fullWidth',
  ...props
}) => {
  return (
    <Divider
      sx={className}
      absolute={absolute}
      flexItem={flexItem}
      orientation={orientation}
      variant={variant}
      {...props}
    />
  );
};

TPDivider.propTypes = {
  className: PropTypes.string,
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  flexItem: PropTypes.bool,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  variant: PropTypes.oneOf(['fullWidth', 'inset', 'middle']),
};

export default TPDivider;
