export const SIGNIN_FORM = 'SIGNIN_FORM';
export const CHOOSE_HUB_FORM = 'CHOOSE_HUB_FORM';

export const WRONG_GP_MODAL = 'WRONG_GP_MODAL';

export const CHOOSE_HUB_MODAL = 'CHOOSE_HUB_MODAL';

export const RESET_PASSWORD_MODAL = 'RESET_PASSWORD_MODAL';

export const EMAIL_VERIFICATION_MODAL = 'EMAIL_VERIFICATION_MODAL';

export const REDIRECT_TO_AFTERPRIME_MODAL = 'REDIRECT_TO_AFTERPRIME_MODAL';

export const FORGOT_PASSWORD_MODAL = 'FORGOT_PASSWORD_MODAL';

export const WITHDRAWAL_TOKEN = 'withdrawalToken';

export const MIGRATION_ERRORS = ['120', '129'];

export const NOTIFY_MIGRATED_CLIENT_PERIOD_IN_DAYS = 60;

export const ERROR_CHOOSING_HUB = '155';
export const ERROR_CHOOSING_HUB_AU = '156';
export const ERROR_CHOOSING_HUB_EN = '157';

export const ERROR_RESET_PASSWORD = '201';

export const ERROR_REDIRECT_AFTERPRIME = '202';

export const ERROR_CAPTCHA = '430';

export const SIGN_IN_STATUS = {
  SUCCESS: 'success',
  NEED_MFA: 'needMfa',
  CHANGE_PASSWORD: 'changePassword',
  REQUIRED_MFA: 'requiredMFA',
  REQUIRED_VERIFICATION: 'requiredVerification',
};

export const REQUIRED_VERIFICATION_MODAL = 'REQUIRED_VERIFICATION_MODAL';
export const EMAIL_VERIFICATION_ERROR = '433';

export const EMAIL_VERIFICATION_INCORRECT = '434';
