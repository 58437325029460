import { handleActions } from 'redux-actions';
import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getAvailablePspSuccess](state, action) {
      return action.response.data;
    },
  },
  defaultState,
);
