import React, { useMemo } from 'react';
import Button from 'modules/common/PrimaryButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import Picker from 'modules/common/Picker';
import { Box } from 'material-latest';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import {
  GLOBAL_PRIME_LABEL,
  GLOBAL_PRIME_X_LABEL,
  GLOBAL_PRIME_PREFIX,
  GLOBAL_PRIME_X_PREFIX,
} from '../../../../../../constants';
import { ERROR_CHOOSING_HUB_AU, ERROR_CHOOSING_HUB_EN } from '../../constants';
import ReCaptcha from '../../../../../common/ReCaptcha';

const SelectHub = ({
  onCancel,
  isVisible,
  classes,
  handleSubmit,
  blockedHubs,
  showCaptcha,
  form,
}) => {
  const { t } = useTranslation();

  const availableHubs = useMemo(() => {
    const HUBS = [];

    if (!blockedHubs.includes(ERROR_CHOOSING_HUB_AU)) {
      HUBS.push({ value: GLOBAL_PRIME_X_PREFIX, label: GLOBAL_PRIME_X_LABEL });
    }

    if (!blockedHubs.includes(ERROR_CHOOSING_HUB_EN)) {
      HUBS.push({ value: GLOBAL_PRIME_PREFIX, label: GLOBAL_PRIME_LABEL });
    }

    return HUBS;
  }, [blockedHubs]);

  return (
    <Dialog
      open={isVisible}
      onClose={onCancel}
      classes={{ paper: classes.dialog }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">{t('common:labels.selectHub')}</DialogTitle>

        <DialogContent className={classes.content}>
          <Box mb={0.5}>
            <Field name="prefix" component={Picker} label="Hub" values={availableHubs} />
          </Box>
          {showCaptcha && (
            <Box mb={2}>
              <ReCaptcha form={form} />
            </Box>
          )}
          <Button
            fullWidth
            className={classes.confirmButton}
            color="primary"
            size="large"
            label={t('common:buttons.signIn')}
            type="submit"
          />
        </DialogContent>
      </form>
    </Dialog>
  );
};

SelectHub.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  blockedHubs: PropTypes.array,
  form: PropTypes.string,
  showCaptcha: PropTypes.bool,
};

const SelectHubReduxModal = reduxForm({ destroyOnUnmount: true })(SelectHub);

export default withStyles(styles)(SelectHubReduxModal);
