export default (theme) => ({
  accountDeposit: {
    width: '100%',
    margin: 0,

    [theme.breakpoints.down(1280)]: {
      marginTop: 0,
    },
    [theme.breakpoints.down(768)]: {
      paddingRight: 0,
    },
  },
  accountDepositWithColumn: {
    '& > div:first-child': {
      paddingRight: 16,
      [theme.breakpoints.down(1480)]: {
        paddingRight: 12,
      },
      [theme.breakpoints.down(480)]: {
        paddingRight: 0,
      },
    },

    '& > div:not(:first-child):not(:last-child)': {
      padding: '0 16px',
      [theme.breakpoints.down(1480)]: {
        padding: '0 12px',
      },
      [theme.breakpoints.down(960)]: {
        padding: '0 0 0 0',
      },
      [theme.breakpoints.down(480)]: {
        padding: 0,
      },
    },

    '& > div:last-child': {
      paddingLeft: 16,
      [theme.breakpoints.down(1480)]: {
        paddingLeft: 12,
      },
      [theme.breakpoints.down(960)]: {
        paddingLeft: 0,
      },
    },
  },
  accountNickname: {
    width: 282,
    marginRight: '25px',
    position: 'relative',
    [theme.breakpoints.down(1418)]: {
      width: 260,
    },
    [theme.breakpoints.down(1279)]: {
      width: '100%',
      marginTop: 0,
      marginRight: 0,
    },
    [theme.breakpoints.down(1025)]: {
      width: 260,
      marginTop: 0,
      marginRight: '16px',
    },
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      marginTop: 0,
      marginRight: 0,
    },
    [theme.breakpoints.down(768)]: {
      width: 260,
      marginTop: 0,
      marginRight: '25px',
    },
    [theme.breakpoints.down(640)]: {
      width: '100%',
      marginTop: 0,
      marginRight: 0,
    },
  },
  accountMoney: {
    width: '188px',
    [theme.breakpoints.down(1280)]: {
      marginTop: 32,
      width: '100%',
    },
    [theme.breakpoints.down(1025)]: {
      width: '188px',
      marginTop: 0,
    },
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      marginTop: 32,
    },
    [theme.breakpoints.down(768)]: {
      width: '260px',
      marginTop: 0,
    },
    [theme.breakpoints.down(640)]: {
      width: '100%',
      marginTop: 32,
      marginRight: 0,
    },
  },
  accountMoneyWithColumn: {
    marginTop: 0,
  },
  firstRowColumn: {
    width: '33.3333%',
    marginRight: 0,

    [theme.breakpoints.down(960)]: {
      width: '50%',
    },

    [theme.breakpoints.down(480)]: {
      width: '100%',

      '&:first-child': {
        marginTop: 24,
      },

      '&:not(:first-child)': {
        marginTop: 36,
      },
    },
  },
  column: {
    [theme.breakpoints.down(960)]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    [theme.breakpoints.down(680)]: {
      marginTop: 24,
      display: 'block',

      '& > section:not(:first-child)': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
  accountBalance: {},
  accountBalanceBankwire: {
    position: 'absolute',
    bottom: 8,
  },
  minAmount: {
    position: 'relative',
    top: 8,
  },
  accountBalanceText: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.text.main,
  },
  accountBalanceAmount: {
    color: '#74C3C4',
    fontSize: 12,
  },
  tooltip: {
    color: '#AA5072',
    fontSize: '12px',
    padding: '14px 0 0 0',
    position: 'relative',
    top: 8,
  },
  menuPaperClass: {
    width: 260,
  },
  menuItemClass: {
    width: 260,
    whiteSpace: 'normal',
  },
  conversionRate: {
    maxWidth: 280,
    position: 'relative',
    bottom: 4,
    paddingRight: 10,
  },
  messageText: {
    color: '#36425A',
    fontSize: '12px',
    fontWeight: '300',
  },
  messageValue: {
    color: theme.palette.moonstoneBlue,
    fontSize: '12px',
    fontWeight: 'bold',
  },
  rateLoading: {
    width: '10px',
    height: '10px',
    marginLeft: '7px',
  },
});
