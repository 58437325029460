export default {
  root: (theme) => ({
    position: 'absolute',
    width: '496px',
    maxWidth: '95%',
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    maxHeight: '95%',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: theme.palette.background.main,
    boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
  }),
  rootWizard: {
    '& $root': {
      width: '1104px', //based on design
      boxShadow: 'none',
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  justifyTop: (theme) => ({
    '& $root': {
      top: '2.5%',
      transform: 'translate(-50%, -2.5%) !important',
      [theme.breakpoints.up('sm')]: {
        top: '10%',
        transform: 'translate(-50%, -10%) !important',
      },
    },
  }),
  fullWidth: {
    width: '100%',
  },
  titleContainer: (theme) => ({
    marginBottom: theme.spacing(2),
    overflowWrap: 'break-word',
  }),
  titleEndAdornment: (theme) => ({
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
  }),
  contentContainer: {
    overflow: 'auto',
    width: '100%',
  },
  content: (theme) => ({
    overflow: 'hidden',
    paddingTop: theme.spacing(1), // space for form label
  }),
  closeButton: (theme) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
  }),
  buttonContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(3),
    gap: theme.spacing(2),
  }),
};
