import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  conversionRate: 0,
  availableBanks: [],
};

export default handleActions(
  {
    [actionCreators.currencyCalculatorSuccess](state, action) {
      return { ...state, conversionRate: action.response.data.conversionRate };
    },
    [actionCreators.getThaiBanksSuccess](state, action) {
      return { ...state, thaiBanks: action.response.data.banks };
    },
    [actionCreators.getAvailableLocalBanksSuccess](state, action) {
      return { ...state, availableBanks: action.response.data };
    },
  },
  defaultState,
);
