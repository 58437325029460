import { handleActions } from 'redux-actions';
import {
  cleanupWithdrawalHistory,
  searchWithdrawHistoryFail,
  searchWithdrawHistorySuccess,
} from '../actions';

const defaultState = {
  items: [],
  totalNumber: 0,
};

export default handleActions(
  {
    [searchWithdrawHistorySuccess](state, action) {
      return {
        ...state,
        items: action.response.data || [],
        totalNumber: action.response.data?.length || 0,
      };
    },
    [searchWithdrawHistoryFail](state) {
      return state.totalNumber !== undefined
        ? state
        : {
            ...defaultState,
            totalNumber: 0,
          };
    },
    [cleanupWithdrawalHistory]() {
      return defaultState;
    },
  },
  defaultState,
);
