export default (theme) => ({
  tooltip: {
    color: '#AA5072',
    fontSize: '12px',
    padding: '14px 0 0 0',
    position: 'relative',
    top: 8,
  },
  text: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.simpleGrey,
    lineHeight: '10px',
  },
  amount: {
    color: theme.palette.textColor,
  },
});
