import { createAction } from 'redux-actions';

export const loadPaymentDetailsRequest = createAction('LOAD_PAYMENT_DETAILS_REQUEST');
export const loadPaymentDetailsSuccess = createAction('LOAD_PAYMENT_DETAILS_SUCCESS');
export const loadPaymentDetailsFail = createAction('LOAD_PAYMENT_DETAILS_FAIL');

export const getBankInfoRequest = createAction('GET_BANK_INFO_REQUEST');
export const getBankInfoSuccess = createAction('GET_BANK_INFO_SUCCESS');
export const getBankInfoFail = createAction('GET_BANK_INFO_FAIL');

export const approveWithdrawalViewDetailsRequest = createAction(
  'APPROVE_WITHDRAWAL_VIEW_DETAILS_REQUEST',
);
export const approveWithdrawalViewDetailsSuccess = createAction(
  'APPROVE_WITHDRAWAL_VIEW_DETAILS_SUCCESS',
);
export const approveWithdrawalViewDetailsFail = createAction(
  'APPROVE_WITHDRAWAL_VIEW_DETAILS_FAIL',
);

export const declineWithdrawalViewDetailsRequest = createAction(
  'DECLINE_WITHDRAWAL_VIEW_DETAILS_REQUEST',
);
export const declineWithdrawalViewDetailsSuccess = createAction(
  'DECLINE_WITHDRAWAL_VIEW_DETAILS_SUCCESS',
);
export const declineWithdrawalViewDetailsFail = createAction(
  'DECLINE_WITHDRAWAL_VIEW_DETAILS_FAIL',
);

export const getWithdrawalCommentsRequest = createAction('GET_WITHDRAWAL_COMMENTS_REQUEST');
export const getWithdrawalCommentsSuccess = createAction('GET_WITHDRAWAL_COMMENTS_SUCCESS');
export const getWithdrawalCommentsFail = createAction('GET_WITHDRAWAL_COMMENTS_FAIL');

export const addWithdrawalCommentsRequest = createAction('ADD_WITHDRAWAL_COMMENTS_REQUEST');
export const addWithdrawalCommentsSuccess = createAction('ADD_WITHDRAWAL_COMMENTS_SUCCESS');
export const addWithdrawalCommentsFail = createAction('ADD_WITHDRAWAL_COMMENTS_FAIL');

export const openProcessWithdraw = createAction('OPEN_PROCESS_WITHDRAW');

export const closeProcessWithdraw = createAction('CLOSE_PROCESS_WITHDRAW');

export const processWithdrawalRequest = createAction('PROCESS_WITHDRAWAL_REQUEST');
export const processWithdrawalSuccess = createAction('PROCESS_WITHDRAWAL_SUCCESS');
export const processWithdrawalFail = createAction('PROCESS_WITHDRAWAL_FAIL');

export const approvalWithdrawalRequest = createAction('APPROVAL_WITHDRAWAL_REQUEST');
export const approvalWithdrawalSuccess = createAction('APPROVAL_WITHDRAWAL_SUCCESS');
export const approvalWithdrawalFail = createAction('APPROVAL_WITHDRAWAL_FAIL');

export const getAvailablePspRequest = createAction('GET_AVAILABLE_PSP_REQUEST');
export const getAvailablePspSuccess = createAction('GET_AVAILABLE_PSP_SUCCESS');
export const getAvailablePspFail = createAction('GET_AVAILABLE_PSP_FAIL');
