import { combineReducers } from 'redux';

import withdrawalRequests from '../pages/WithdrawalRequests/reducers';
import smartwayRequests from '../pages/SmartwayRequests/reducers';
import paymentsHistory from '../pages/History/reducers';
import pendingPaymentsHistory from '../pages/PendingHistory/reducers';
import withdrawalDetails from '../pages/ViewDetails/reducers';
import withdrawalComments from '../pages/ViewDetails/reducers/comments';
import withdrawalAvailablePsp from '../pages/ViewDetails/reducers/availablePsp';
import liveAccounts from './liveAccountsReducer';

const reducer = combineReducers({
  withdrawalRequests,
  withdrawalDetails,
  withdrawalComments,
  smartwayRequests,
  liveAccounts,
  paymentsHistory,
  pendingPaymentsHistory,
  withdrawalAvailablePsp,
});

export default reducer;
